
























import { useLocalFilter, useProductDetail } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { ProductUomConversionDto } from "@/models/interface/master-product";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";

@Component
export default class SelectUomConv extends Mixins(MNotificationVue) {
  useLocalFilter = useLocalFilter;

  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: String, default: "" })
  productId!: string;

  options: Array<Option<ProductUomConversionDto>> = [];
  loading = false;

  @Watch("productId", { immediate: true })
  onChangeProductId(newValue: string): void {
    this.options = [];
    if (!newValue) return;
    this.getDetail(newValue);
  }

  onChange(e: string | undefined): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  async getDetail(productId: string): Promise<void> {
    try {
      this.loading = true;
      const response = await useProductDetail(productId);
      this.mapToOptions(response.uomConversions);
    } catch (error) {
      this.showNotifError("notif_process_fail");
    } finally {
      this.loading = false;
    }
  }

  mapToOptions(param: ProductUomConversionDto[]): void {
    this.options = param.map<Option<ProductUomConversionDto>>((item, idx) => ({
      label: item.uomSource,
      value: item.uomSourceId,
      meta: item,
      key: idx,
    }));
  }

  findOption(e?: string): Option<ProductUomConversionDto> | undefined {
    return this.options.find(item => item.value === e);
  }
}
