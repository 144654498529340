
























import { Component, Vue, Watch } from "vue-property-decorator";
import { useLocalFilter } from "@/hooks";
import { Option } from "@/models/class/option.class";
import type { ProductUnit } from "../types/stock-opname-table.types";
import type { Row } from "../types/stock-adjustment-table.types";

const SelectProductUomProps = Vue.extend({
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    unit: {
      type: Object as () => Row,
    },
  },
});

@Component
export default class SelectProductUom extends SelectProductUomProps {
  useLocalFilter;

  options: Option[] = [];

  created(): void {
    this.useLocalFilter = useLocalFilter;
  }

  @Watch("unit", { deep: true, immediate: true })
  onChangeUomProps(newValue: ProductUnit) {
    if (newValue) {
      this.options = this.mapToOptions(newValue);
    }
  }

  onChange(e: string | undefined): void {
    this.$emit("change", e);
  }

  mapToOptions(param: ProductUnit): Option[] {
    const { id, name } = param;
    const option: Option = new Option(id, name, id);
    return [option];
  }
}
