export class Option<T = Record<string, any>> {
  value: string;
  label: string;
  key: string | number;
  title?: string;
  meta?: T;

  constructor(
    value: string,
    label: string,
    key: string | number,
    title?: string,
    meta?: T
  ) {
    this.value = value;
    this.label = label;
    this.meta = meta;
    this.key = key;
    this.title = title;
  }
}
