var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: {
      size: "small",
      "data-source": _vm.form.stockAdjustmentRows,
      columns: _vm.columns,
      pagination: {
        showSizeChanger: true,
        pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
        showTotal: function(total) {
          return _vm.$t("lbl_total_items", { total: total })
        }
      },
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      },
      scroll: { x: 1200 }
    },
    scopedSlots: _vm._u([
      {
        key: "nullable",
        fn: function(text) {
          return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
        }
      },
      {
        key: "number",
        fn: function(text) {
          return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
        }
      },
      {
        key: "title",
        fn: function() {
          return [
            _c(
              "a-button-group",
              [
                _c("a-button", {
                  attrs: { icon: "plus", type: "primary" },
                  on: { click: _vm.addRow }
                }),
                _c("a-button", {
                  attrs: { icon: "delete", type: "danger" },
                  on: { click: _vm.handleDeleteRow }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "productCode",
        fn: function(text, row) {
          return [
            _c("SelectProductInventory", {
              staticClass: "w-100",
              attrs: {
                "warehouse-location-id": _vm.form.warehouseLocationId,
                "product-brand": _vm.form.brand,
                "product-category-id": _vm.form.productCategoryId
              },
              on: {
                "update:meta": function($event) {
                  return _vm.onChangeProduct($event, row)
                }
              },
              model: {
                value: row.productCode,
                callback: function($$v) {
                  _vm.$set(row, "productCode", $$v)
                },
                expression: "row.productCode"
              }
            })
          ]
        }
      },
      {
        key: "productName",
        fn: function(text, row) {
          return [
            _c("SelectProductInventory", {
              staticClass: "w-100",
              attrs: {
                "option-label": "name",
                "warehouse-location-id": _vm.form.warehouseLocationId,
                "product-brand": _vm.form.brand,
                "product-category-id": _vm.form.productCategoryId
              },
              on: {
                "update:meta": function($event) {
                  return _vm.onChangeProduct($event, row)
                }
              },
              model: {
                value: row.productName,
                callback: function($$v) {
                  _vm.$set(row, "productName", $$v)
                },
                expression: "row.productName"
              }
            })
          ]
        }
      },
      {
        key: "uom",
        fn: function(text, row) {
          return [
            _c("SelectUomConv", {
              staticClass: "w-100",
              attrs: { "product-id": row.productId },
              on: {
                "update:meta": function($event) {
                  return _vm.onChangeUom($event, row)
                }
              },
              model: {
                value: row.uomName,
                callback: function($$v) {
                  _vm.$set(row, "uomName", $$v)
                },
                expression: "row.uomName"
              }
            })
          ]
        }
      },
      {
        key: "physicalQty",
        fn: function(text, row) {
          return [
            _c("a-input-number", {
              attrs: {
                formatter: _vm.formatterNumber,
                parser: _vm.reverseFormatNumber,
                precision: _vm.storeBaseDecimalPlace
              },
              model: {
                value: row.physicalQty,
                callback: function($$v) {
                  _vm.$set(row, "physicalQty", _vm._n($$v))
                },
                expression: "row.physicalQty"
              }
            })
          ]
        }
      },
      {
        key: "differenceQty",
        fn: function(text, row) {
          return [_c("span", [_vm._v(_vm._s(_vm.useCountQtyDifference(row)))])]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }