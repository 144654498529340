







































































import SelectProduct from "@/components/custom/select/SelectProduct.vue";
import SelectUomConv from "@/components/custom/select/SelectUomConv.vue";
import { useProduct } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { InventoryLineResponseDto } from "@/models/interface/inventory";
import {
  ProductDetailDto,
  ProductUomConversionDto,
} from "@/models/interface/master-product";
import { FormValue, StockAdjRow } from "@/store/stockAdj.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { useCountQtyDifference } from "@/views/logistic/stock-adjustment/hooks";
import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";
import SelectProductInventory from "./SelectProductInventory.vue";

@Component({
  components: {
    SelectProduct,
    SelectUomConv,
    SelectProductInventory,
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) => st.preferenceStore.baseDecimalPlace,
    }),
    ...mapGetters({
      form: "stockAdjStore/getForm",
    }),
  },
  methods: {
    ...mapActions({
      addRow: "stockAdjStore/addStockAdjRow",
      deleteRow: "stockAdjStore/deleteStockAdjRow",
    }),
  },
})
export default class FormTableOpname extends Mixins(MNotificationVue) {
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  useCountQtyDifference = useCountQtyDifference;

  form!: FormValue;
  addRow!: () => void;
  deleteRow!: (payload: Array<number>) => void;

  selectedRowKeys: Array<number> = [];

  columns = [
    {
      // user pilih dari master product
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      //   width: "20%",
      scopedSlots: { customRender: "productCode" },
    },
    {
      // user pilih dari master product
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      //   width: "20%",
      scopedSlots: { customRender: "productName" },
    },
    {
      // autofill dari product yang dipilih
      // statis
      title: this.$t("lbl_brand"),
      dataIndex: "productMerk",
      //   width: "20%",
      scopedSlots: { customRender: "nullable" },
    },
    {
      // qty inventory on hand
      // statis
      title: this.$t("lbl_qty_total"),
      dataIndex: "qty",
      //   width: "20%",
      scopedSlots: { customRender: "number" },
    },
    {
      // user input
      title: this.$t("lbl_uom"),
      dataIndex: "uomName",
      //   width: "20%",
      scopedSlots: { customRender: "uom" },
    },
    {
      // user input
      title: this.$t("lbl_qty_physical"),
      dataIndex: "physicalQty",
      scopedSlots: { customRender: "physicalQty" },
    },
    {
      // dari perhitungan
      title: this.$t("lbl_qty_difference"),
      dataIndex: "differenceQty",
      scopedSlots: { customRender: "differenceQty" },
    },
  ];

  onSelectChange(keys: Array<number>): void {
    this.selectedRowKeys = keys;
  }

  onChangeProduct(
    e: Option<InventoryLineResponseDto> | undefined,
    row: StockAdjRow
  ): void {
    row.productCode = e?.meta?.product?.code || "";
    row.productId = e?.meta?.product?.id || "";
    row.productName = e?.meta?.product?.name || "";
    row.productMerk = e?.meta?.product?.merk || "";
    row.uomId = "";
    row.uomName = "";
    row.qty = e?.meta?.onHand || 0;

    if (!row.productId) return;
    this.getDetailProduct(row.productId, ({ baseUnit, baseUnitId }) => {
      row.uomId = baseUnitId || "";
      row.uomName = baseUnit || "";
    });
  }

  getDetailProduct(id: string, cb: (payload: ProductDetailDto) => void): void {
    const { findById } = useProduct();
    findById(id).then(cb);
  }

  onChangeUom(
    opt: Option<ProductUomConversionDto> | undefined,
    row: StockAdjRow
  ): void {
    row.uomId = opt?.meta?.uomSourceId || "";
    row.uomName = opt?.label || "";
  }

  handleDeleteRow(): void {
    this.showConfirmationDeleteItems(() => {
      this.deleteRow(this.selectedRowKeys);
      this.selectedRowKeys = [];
    });
  }
}
